<template>
  <div class="content pad-bottom--vxl">
    <div class="grid-container pad-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-8 medium-offset-2 pad-top--xxl">
          <h1 class="text-white ">Reset password</h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-8 medium-offset-2">
          <p class="text-white">Please enter your new password below.</p>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-2 text-center">
          <input
            v-model="password"
            class="bg--transparent"
            type="password"
            required
            placeholder="Password" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pb-10">
        <div class="cell small-6 medium-3 medium-offset-2">
          <button @click="reset" class="button rounded-tall expanded">Send</button>
        </div>
      </div>
      <div class="grid-x grid-padding-x pb-10">
        <div class="cell small-6 medium-8 medium-offset-2">
          <div
            @click="message = ''"
            v-if="message.length > 0"
            class="message text-white">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from '../axios';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      message: '',
    };
  },
  methods: {
    reset() {
      const postData = {};
      postData.password = this.password;
      const hashTest = this.$route.params.hash;
      const customerId = this.$route.params.id;
      axios.post(`/users/resetPassword/${hashTest}/${customerId}.json`, postData)
        .then((response) => {
          this.message = response.data;
          this.loginNow = true;
        });
    },
  },
};
</script>
